import React, { useRef, useEffect } from "react";
import Img from "gatsby-image";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import WrapTypo from "../components/WrapTypo";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/footer";

const Concept = () => {
  const data = useStaticQuery(graphql`
    query {
      saw: file(relativePath: { eq: "saw.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mtchokai: file(relativePath: { eq: "mtchokai.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Header />
      <Box maxWidth="900px" margin="auto">
        <Box width="95%" height="400px" margin="auto" mt={5} mb={5}>
          <Head />
          {/* <Hidden mdUp>
            <Box justifyContent="flex-start" mb={5}>
              <WrapTypo fw={"700"} lh="2">
                知っているだろうか 同じ設計図、同じ素材でも、
                違う強度の家ができる事。
              </WrapTypo>
            </Box>
            <Box justifyContent="flex-start" mb={4}>
              <WrapTypo
                fs="80%"
                lh="200%"
                style={{ textOrientation: "upright", fontWeight: "500px" }}
              >
                突き詰めると、わずかな差の積み重ねである。
                木材にも上下がある、断熱材はふっくら柔らか に敷き詰める。
                板が割れないようにネジの締めは9割七分まで。
                きちんとした知識があるかどうか。
                一つ一つの作業に妥協を許さない。それが、HAYAFUJIブランド
              </WrapTypo>
            </Box>
          </Hidden> */}
          <Hidden smDown>
            <Box height="400px">
              <Img fluid={data.saw.childImageSharp.fluid} />

              <Box margin="auto" height="300px">
                <WrapTypo
                  lh="2"
                  style={{
                    writingMode: "vertical-rl",
                    textOrientation: "upright"
                  }}
                >
                  突き詰めると、わずかな差の積み重ねである
                  木材にも上下がある、断熱材はふっくら柔らか に敷き詰める。
                  板が割れないようにネジの締めは9割七分まで。
                  きちんとした知識があるかどうか。
                  きちんとそれを再現できるかどうか。
                  一つ一つの作業に妥協を許さない。 それが、HAYAFUJIブランド
                  一つ一つの作業に妥協を許さない。 それが、HAYAFUJIブランド
                </WrapTypo>
              </Box>

              <Box
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "upright",
                  margin: "auto"
                }}
              >
                <WrapTypo lh="2" fs="130%" fw="900">
                  知っているだろうか
                  <br />
                  同じ設計図、同じ素材でも、
                  <br />
                  違う強度の家ができる事。
                </WrapTypo>
              </Box>
              <Box
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "upright",
                  marginLeft: "auto"
                }}
              >
                <WrapTypo fw="500" ls="10px" lh="250%" fs="24px">
                  わたしたちの信念
                </WrapTypo>
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Box>

      <Box mb={8}>
        <Img fluid={data.mtchokai.childImageSharp.fluid} />
      </Box>
      <Box margin="auto" mb={8}>
        <Box width="95%" margin="auto" mt={5} mb={8}>
          <Head />
          {/* <Hidden mdUp>
            <Box justifyContent="flex-start" mb={5}>
              <WrapTypo fw={70} lh="2">
                お客様の歩んできた人生に
                <br />
                寄り添う住まい造り。
              </WrapTypo>
            </Box>
            <Box justifyContent="flex-start" mb={4}>
              <WrapTypo fs="80%" lh="2" style={{ fontWeight: "500px" }}>
                ・夢を叶える(この部屋はこうしたい、この部屋にはこれをおきたい)一緒に創出する
                <br />
                ・やりたいことを予算の中から最大限 ・予算のすり合わせ
                <br />
                ・どのメーカーのどの素材を使うか、つまり０から。
                <br />
                ・写真、イメージの中からできるできないを判別する。
                <br />
                ・人が家に合わせる建売ではなく、それはお客様の生活には沿っていない。早藤は０から設計するため、お客様の人生観、価値観に沿って建築できる。
              </WrapTypo>
            </Box>
            <Box justifyContent="flex-start" mb={4}>
              <WrapTypo fs="80%">
                建築の世界において、早熟の天才はいないとHAYAFUJIは考えております。設計士も職人も日々の経験と修行があって納得のいく仕事ができるのだと思います。一つ一つの仕事を丁寧に対応していくことを会社の経営理念としています。
                　デザインだけにこだわりすぎた、使い勝手の悪い家。価格だけにとらわれ、平凡で面白みに欠ける家。HYAFUJIの目指すところはどちらでもなく、完全自由設計により一件一件、お客様のニーズにしっかりと答えた上で「HAYAFUJIらしさ」「遊びごごろ」を持って設計し、ご提案したいと考えております。その取り組みが何気ない日常に彩を与得ることができたら、幸いです。
                　どんなに施工性の良い建材や利便性に優れた工法を採用したとしても、家を造るのは人間です。必ず造り手の質の違いはあります。小さい頃から職人の世界を見てきたからこそ職人をリスペクトし、信頼できる自社の職人(大工さん)と仕事をすることを基本としております。
              </WrapTypo>
            </Box>
          </Hidden> */}
          <Hidden smDown>
            <Box mb={8}>
              <Box height="300px">
                <WrapTypo
                  lh="250%"
                  fs="22px"
                  fw="700"
                  ls="10px"
                  style={{
                    writingMode: "vertical-rl",
                    textOrientation: "upright",
                    marginLeft: "auto"
                  }}
                >
                  大切にしていること
                </WrapTypo>
                <WrapTypo
                  lh="200%"
                  style={{
                    writingMode: "vertical-rl",
                    textOrientation: "upright"
                  }}
                >
                  　建築の世界において、早熟の天才はいないとHAYAFUJIは考えております。設計士も職人も日々の経験と修行があって納得のいく仕事ができるのだと思います。一つ一つの仕事を丁寧に対応していくことを会社の経営理念としています。
                  　デザインだけにこだわりすぎた、使い勝手の悪い家。
                  <br />
                  <br />
                  価格だけにとらわれ、平凡で面白みに欠ける家。HYAFUJIの目指すところはどちらでもなく、完全自由設計により一件一件、お客様のニーズにしっかりと答えた上で「HAYAFUJIらしさ」「遊びごごろ」を持って設計し、ご提案したいと考えております。その取り組みが何気ない日常に彩を与得ることができたら、幸いです。
                  　どんなに施工性の良い建材や利便性に優れた工法を採用したとしても、家を造るのは人間です。必ず造り手の質の違いはあります。小さい頃から職人の世界を見てきたからこそ職人をリスペクトし、信頼できる自社の職人(大工さん)と仕事をすることを基本としております。
                </WrapTypo>
              </Box>
            </Box>
          </Hidden>
          <Box mb={8}>
            <Hidden mdUp>
              <Box justifyContent="flex-start" mb={5}>
                <WrapTypo fw={70} lh="250%" fs="18px">
                  理想のマイホームを建てるには
                  <br />
                  HAYAFUJIでなくてはならない。
                  <br />
                  そう言って頂けるよう、
                  <br />
                  日々邁進しております。
                </WrapTypo>
              </Box>

              <Box justifyContent="flex-start">
                <WrapTypo fs="80%" lh="2" style={{ fontWeight: "500px" }}>
                  人生の中で一番い大きい買い物が家を建てる事です。一言では言い表す事のできない、様々な想いを持って家を建てる決心をされていると思います。だからこそ後悔はさせたくない、お客様には必ず満足してもらいたい。そのためにはお客様のライフスタイル・趣味嗜好をしっかりと理解する必要ことが何より大事だと考えています。お客様の中にあるイメージを形として創造する事が私たちの仕事。つまりは、計画から完成、そしてその後も引き続き信頼関係を築いていくことを一番大切にしております。
                </WrapTypo>
              </Box>
            </Hidden>
            <Hidden smDown>
              <Box mb={8}>
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}>
                    <Box>
                      <WrapTypo
                        style={{
                          textOrientation: "upright",
                          writingMode: "vertical-rl",
                          margin: "auto"
                        }}
                      >
                        早藤工務店代表取締役　早藤亮
                      </WrapTypo>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box height="300px">
                      <WrapTypo
                        lh="230%"
                        textAlign="justify"
                        style={{
                          writingMode: "vertical-rl",
                          textOrientation: "upright",
                          margin: "auto"
                        }}
                      >
                        人生の中で一番い大きい買い物が家を建てる事です。一言では言い表す事のできない、様々な想いを持って家を建てる決心をされていると思います。だからこそ後悔はさせたくない、お客様には必ず満足してもらいたい。そのためにはお客様のライフスタイル・趣味嗜好をしっかりと理解する必要ことが何より大事だと考えています。お客様の中にあるイメージを形として創造する事が私たちの仕事。つまりは、計画から完成、そしてその後も引き続き信頼関係を築いていくことを一番大切にしております。
                      </WrapTypo>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box height="350px">
                      <WrapTypo
                        lh="250%"
                        fs="18px"
                        style={{
                          writingMode: "vertical-rl",
                          textOrientation: "upright",
                          fontWeight: "70%",
                          marginLeft: "auto"
                        }}
                      >
                        家を建てるという事
                      </WrapTypo>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Concept;
